<template>
  <div class="appendix">
    <div v-html="content" class="content" />
    <div style="width: 100%;text-align: center;">
      <van-button type="primary" class="btn" @click="before" :disabled="isDisabled">
        我已认真阅读
      </van-button>
    </div>
  </div>
</template>

<script>
import { Button } from 'vant';
import { useStore } from 'vuex';
import { onMounted, ref } from 'vue';
import httpApi from '../../utils/httpApi';

const content = `
ISDA®

International Swaps and Derivatives Association, Inc.

2002 MASTER AGREEMENT

dated as of ________
BEEVEST FINANCIAL TRADING LIMITED _______ and ________

have entered and/or anticipate entering into one or more transactions (each a “Transaction”) that are or will be governed by this 2002 Master Agreement, which includes the schedule (the “Schedule”), and the documents and other confirming evidence (each a “Confirmation”) exchanged between the parties or otherwise effective for the purpose of confirming or evidencing those Transactions. This 2002 Master Agreement and the Schedule are together referred to as this “Master Agreement”.

Accordingly, the parties agree as follows:―

1.Interpretation

(a)Definitions. The terms defined in Section 14 and elsewhere in this Master Agreement will have the meanings therein specified for the purpose of this Master Agreement.

(b)Inconsistency. In the event of any inconsistency between the provisions of the Schedule and the other provisions of this Master Agreement, the Schedule will prevail. In the event of any inconsistency between the provisions of any Confirmation and this Master Agreement, such Confirmation will prevail for the purpose of the relevant Transaction.

(c)Single Agreement. All Transactions are entered into in reliance on the fact that this Master Agreement and all Confirmations form a single agreement between the parties (collectively referred to as this “Agreement”), and the parties would not otherwise enter into any Transactions.

2.Obligations

(a)General Conditions.

(i)Each party will make each payment or delivery specified in each Confirmation to be made by it, subject to the other provisions of this Agreement.

(ii)Payments under this Agreement will be made on the due date for value on that date in the place of the account specified in the relevant Confirmation or otherwise pursuant to this Agreement, in freely transferable funds and in the manner customary for payments in the required currency. Where settlement is by delivery (that is, other than by payment), such delivery will be made for receipt on the due date in the manner customary for the relevant obligation unless otherwise specified in the relevant Confirmation or elsewhere in this Agreement.

Copyright © 2002 by International Swaps and Derivatives Association, Inc.

(iii)Each obligation of each party under Section 2(a)(i) is subject to (1) the condition precedent that no Event of Default or Potential Event of Default with respect to the other party has occurred and is continuing,

(2) the condition precedent that no Early Termination Date in respect of the relevant Transaction has occurred or been effectively designated and (3) each other condition specified in this Agreement to be a condition precedent for the purpose of this Section 2(a)(iii).

(b)Change of Account. Either party may change its account for receiving a payment or delivery by giving notice to the other party at least five Local Business Days prior to the Scheduled Settlement Date for the payment or delivery to which such change applies unless such other party gives timely notice of a reasonable objection to such change.

(c)Netting of Payments. If on any date amounts would otherwise be payable:―

(i)in the same currency; and

(ii)in respect of the same Transaction, by each party to the other, then, on such date, each party’s obligation to make payment of any such amount will be automatically satisfied and discharged and, if the aggregate amount that would otherwise have been payable by one party exceeds the aggregate amount that would otherwise have been payable by the other party, replaced by an obligation upon the party by which the larger aggregate amount would have been payable to pay to the other party the excess of the larger aggregate amount over the smaller aggregate amount.

The parties may elect in respect of two or more Transactions that a net amount and payment obligation will be determined in respect of all amounts payable on the same date in the same currency in respect of those Transactions, regardless of whether such amounts are payable in respect of the same Transaction. The election may be made in the Schedule or any Confirmation by specifying that “Multiple Transaction Payment Netting” applies to the Transactions identified as being subject to the election (in which case clause (ii) above will not apply to such Transactions). If Multiple Transaction Payment Netting is applicable to Transactions, it will apply to those Transactions with effect from the starting date specified in the Schedule or such Confirmation, or, if a starting date is not specified in the Schedule or such Confirmation, the starting date otherwise agreed by the parties in writing. This election may be made separately for different groups of Transactions and will apply separately to each pairing of Offices through which the parties make and receive payments or deliveries.

(d)Deduction or Withholding for Tax.

(i)Gross-Up. All payments under this Agreement will be made without any deduction or withholding for or on account of any Tax unless such deduction or withholding is required by any applicable law, as modified by the practice of any relevant governmental revenue authority, then in effect. If a party is so required to deduct or withhold, then that party (“X”) will:―

(1)promptly notify the other party (“Y”) of such requirement;

(2)pay to the relevant authorities the full amount required to be deducted or withheld (including the full amount required to be deducted or withheld from any additional amount paid by X to Y under this Section 2(d)) promptly upon the earlier of determining that such deduction or withholding is required or receiving notice that such amount has been assessed against Y;

(3)promptly forward to Y an official receipt (or a certified copy), or other documentation reasonably acceptable to Y, evidencing such payment to such authorities; and



(4)if such Tax is an Indemnifiable Tax, pay to Y, in addition to the payment to which Y is otherwise entitled under this Agreement, such additional amount as is necessary to ensure that the net amount actually received by Y (free and clear of Indemnifiable Taxes, whether assessed against X or Y) will equal the full amount Y would have received had no such deduction or withholding been required. However, X will not be required to pay any additional amount to Y to the extent that it would not be required to be paid but for:―

(A) the failure by Y to comply with or perform any agreement contained in Section 4(a)(i), 4(a)(iii) or 4(d); or

(B) the failure of a representation made by Y pursuant to Section 3(f) to be accurate and true unless such failure would not have occurred but for (I) any action taken by a taxing authority, or brought in a court of competent jurisdiction, after a Transaction is entered into (regardless of whether such action is taken or brought with respect to a party to this Agreement) or (II) a Change in Tax Law.

(ii)Liability. If:―

(1)X is required by any applicable law, as modified by the practice of any relevant governmental revenue authority, to make any deduction or withholding in respect of which X would not be required to pay an additional amount to Y under Section 2(d)(i)(4);

(2)X does not so deduct or withhold; and

(3)a liability resulting from such Tax is assessed directly against X, then, except to the extent Y has satisfied or then satisfies the liability resulting from such Tax, Y will promptly pay to X the amount of such liability (including any related liability for interest, but including any related liability for penalties only if Y has failed to comply with or perform any agreement contained in Section 4(a)(i), 4(a)(iii) or 4(d)).

3.Representations

Each party makes the representations contained in Sections 3(a), 3(b), 3(c), 3(d), 3(e) and 3(f) and, if specified in the Schedule as applying, 3(g) to the other party (which representations will be deemed to be repeated by each party on each date on which a Transaction is entered into and, in the case of the representations in Section 3(f), at all times until the termination of this Agreement). If any “Additional Representation” is specified in the Schedule or any Confirmation as applying, the party or parties specified for such Additional Representation will make and, if applicable, be deemed to repeat such Additional Representation at the time or times specified for such Additional Representation.

(a)Basic Representations.

(i)Status. It is duly organised and validly existing under the laws of the jurisdiction of its organisation or incorporation and, if relevant under such laws, in good standing;

(ii)Powers. It has the power to execute this Agreement and any other documentation relating to this Agreement to which it is a party, to deliver this Agreement and any other documentation relating to this Agreement that it is required by this Agreement to deliver and to perform its obligations under this Agreement and any obligations it has under any Credit Support Document to which it is a party and has taken all necessary action to authorise such execution, delivery and performance;


(iii)No Violation or Conflict. Such execution, delivery and performance do not violate or conflict with any law applicable to it, any provision of its constitutional documents, any order or judgment of any court or other agency of government applicable to it or any of its assets or any contractual restriction binding on or affecting it or any of its assets;

(iv) Consents. All governmental and other consents that are required to have been obtained by it with respect to this Agreement or any Credit Support Document to which it is a party have been obtained and are in full force and effect and all conditions of any such consents have been complied with; and

(v) Obligations Binding. Its obligations under this Agreement and any Credit Support Document to which it is a party constitute its legal, valid and binding obligations, enforceable in accordance with their respective terms (subject to applicable bankruptcy, reorganisation, insolvency, moratorium or similar laws affecting creditors’ rights generally and subject, as to enforceability, to equitable principles of general application (regardless of whether enforcement is sought in a proceeding in equity or at law)).

(b)Absence of Certain Events. No Event of Default or Potential Event of Default or, to its knowledge,

Termination Event with respect to it has occurred and is continuing and no such event or circumstance would occur as a result of its entering into or performing its obligations under this Agreement or any Credit Support Document to which it is a party.

(c)Absence of Litigation. There is not pending or, to its knowledge, threatened against it, any of its Credit

Support Providers or any of its applicable Specified Entities any action, suit or proceeding at law or in equity or before any court, tribunal, governmental body, agency or official or any arbitrator that is likely to affect the legality, validity or enforceability against it of this Agreement or any Credit Support Document to which it is a party or its ability to perform its obligations under this Agreement or such Credit Support Document.

(d)Accuracy of Specified Information. All applicable information that is furnished in writing by or on behalf of it to the other party and is identified for the purpose of this Section 3(d) in the Schedule is, as of the date of the information, true, accurate and complete in every material respect.

(e)Payer Tax Representation. Each representation specified in the Schedule as being made by it for the purpose of this Section 3(e) is accurate and true.

(f) Payee Tax Representations. Each representation specified in the Schedule as being made by it for the purpose of this Section 3(f) is accurate and true.

(g) No Agency. It is entering into this Agreement, including each Transaction, as principal and not as agent of any person or entity.

4.Agreements

Each party agrees with the other that, so long as either party has or may have any obligation under this Agreement or under any Credit Support Document to which it is a party:― 
(a)Furnish Specified Information. It will deliver to the other party or, in certain cases under clause (iii) below, to such government or taxing authority as the other party reasonably directs:―

(i)any forms, documents or certificates relating to taxation specified in the Schedule or any

Confirmation;

(ii)any other documents specified in the Schedule or any Confirmation; and

(iii)upon reasonable demand by such other party, any form or document that may be required or reasonably requested in writing in order to allow such other party or its Credit Support Provider to make a payment under this Agreement or any applicable Credit Support Document without any deduction or withholding for or on account of any Tax or with such deduction or withholding at a reduced rate (so long as the completion, execution or submission of such form or document would not materially prejudice the legal or commercial position of the party in receipt of such demand), with any such form or document to be accurate and completed in a manner reasonably satisfactory to such other party and to be executed and to be delivered with any reasonably required certification, in each case by the date specified in the Schedule or such Confirmation or, if none is specified, as soon as reasonably practicable.

(b)Maintain Authorisations. It will use all reasonable efforts to maintain in full force and effect all consents of any governmental or other authority that are required to be obtained by it with respect to this Agreement or any Credit Support Document to which it is a party and will use all reasonable efforts to obtain any that may become necessary in the future.

(c)Comply With Laws. It will comply in all material respects with all applicable laws and orders to which it may be subject if failure so to comply would materially impair its ability to perform its obligations under this Agreement or any Credit Support Document to which it is a party.

(d)Tax Agreement. It will give notice of any failure of a representation made by it under Section 3(f) to be accurate and true promptly upon learning of such failure.

(e)Payment of Stamp Tax. Subject to Section 11, it will pay any Stamp Tax levied or imposed upon it or in respect of its execution or performance of this Agreement by a jurisdiction in which it is incorporated, organised, managed and controlled or considered to have its seat, or where an Office through which it is acting for the purpose of this Agreement is located (“Stamp Tax Jurisdiction”), and will indemnify the other party against any Stamp Tax levied or imposed upon the other party or in respect of the other party’s execution or performance of this Agreement by any such Stamp Tax Jurisdiction which is not also a Stamp Tax Jurisdiction with respect to the other party.

5.Events of Default and Termination Events

(a)Events of Default. The occurrence at any time with respect to a party or, if applicable, any Credit Support Provider of such party or any Specified Entity of such party of any of the following events constitutes (subject to Sections 5(c) and 6(e)(iv)) an event of default (an “Event of Default”) with respect to such party:―

(i)Failure to Pay or Deliver. Failure by the party to make, when due, any payment under this Agreement or delivery under Section 2(a)(i) or 9(h)(i)(2) or (4) required to be made by it if such failure is not remedied on or before the first Local Business Day in the case of any such payment or the first Local Delivery Day in the case of any such delivery after, in each case, notice of such failure is given to the party;

(ii)Breach of Agreement; Repudiation of Agreement.

(1)Failure by the party to comply with or perform any agreement or obligation (other than an obligation to make any payment under this Agreement or delivery under Section 2(a)(i) or 9(h)(i)(2) or (4) or to give notice of a Termination Event or any agreement or obligation under Section 4(a)(i), 4(a)(iii) or 4(d)) to be complied with or performed by the party in accordance with this Agreement if such failure is not remedied within 30 days after notice of such failure is given to the party; or

(2)the party disaffirms, disclaims, repudiates or rejects, in whole or in part, or challenges the validity of, this Master Agreement, any Confirmation executed and delivered by that party or any

Transaction evidenced by such a Confirmation (or such action is taken by any person or entity appointed or empowered to operate it or act on its behalf);

(iii)Credit Support Default.

(1)Failure by the party or any Credit Support Provider of such party to comply with or perform any agreement or obligation to be complied with or performed by it in accordance with any Credit Support Document if such failure is continuing after any applicable grace period has elapsed;

(2)the expiration or termination of such Credit Support Document or the failing or ceasing of such Credit Support Document, or any security interest granted by such party or such Credit

Support Provider to the other party pursuant to any such Credit Support Document, to be in full force and effect for the purpose of this Agreement (in each case other than in accordance with its terms) prior to the satisfaction of all obligations of such party under each Transaction to which such Credit Support Document relates without the written consent of the other party; or

(3)the party or such Credit Support Provider disaffirms, disclaims, repudiates or rejects, in whole or in part, or challenges the validity of, such Credit Support Document (or such action is taken by any person or entity appointed or empowered to operate it or act on its behalf);

(iv) Misrepresentation. A representation (other than a representation under Section 3(e) or 3(f)) made or repeated or deemed to have been made or repeated by the party or any Credit Support Provider of such party in this Agreement or any Credit Support Document proves to have been incorrect or misleading in any material respect when made or repeated or deemed to have been made or repeated;

(v) Default Under Specified Transaction. The party, any Credit Support Provider of such party or any applicable Specified Entity of such party:―

(l) defaults (other than by failing to make a delivery) under a Specified Transaction or any credit support arrangement relating to a Specified Transaction and, after giving effect to any applicable notice requirement or grace period, such default results in a liquidation of, an acceleration of obligations under, or an early termination of, that Specified Transaction;

(2)defaults, after giving effect to any applicable notice requirement or grace period, in making any payment due on the last payment or exchange date of, or any payment on early termination of, a Specified Transaction (or, if there is no applicable notice requirement or grace period, such default continues for at least one Local Business Day);

(3)defaults in making any delivery due under (including any delivery due on the last delivery or exchange date of) a Specified Transaction or any credit support arrangement relating to a Specified Transaction and, after giving effect to any applicable notice requirement or grace period, such default results in a liquidation of, an acceleration of obligations under, or an early termination of, all transactions outstanding under the documentation applicable to that Specified Transaction; or

(4)disaffirms, disclaims, repudiates or rejects, in whole or in part, or challenges the validity of, a Specified Transaction or any credit support arrangement relating to a Specified Transaction that is, in either case, confirmed or evidenced by a document or other confirming evidence executed and delivered by that party, Credit Support Provider or Specified Entity (or such action is taken by any person or entity appointed or empowered to operate it or act on its behalf);

(vi) Cross-Default. If “Cross-Default” is specified in the Schedule as applying to the party, the occurrence or existence of:―

(l) a default, event of default or other similar condition or event (however described) in respect of such party, any Credit Support Provider of such party or any applicable Specified Entity of such party under one or more agreements or instruments relating to Specified Indebtedness of any of them (individually or collectively) where the aggregate principal amount of such agreements or instruments, either alone or together with the amount, if any, referred to in clause (2) below, is not less than the applicable Threshold Amount (as specified in the Schedule) which has resulted in such Specified Indebtedness becoming, or becoming capable at such time of being declared, due and payable under such agreements or instruments before it would otherwise have been due and payable; or

(2) a default by such party, such Credit Support Provider or such Specified Entity (individually or collectively) in making one or more payments under such agreements or instruments on the due date for payment (after giving effect to any applicable notice requirement or grace period) in an aggregate amount, either alone or together with the amount, if any, referred to in clause (1) above, of not less than the applicable Threshold Amount;

(vii) Bankruptcy. The party, any Credit Support Provider of such party or any applicable Specified Entity of such party:―

(l) is dissolved (other than pursuant to a consolidation, amalgamation or merger); (2) becomes insolvent or is unable to pay its debts or fails or admits in writing its inability generally to pay its debts as they become due; (3) makes a general assignment, arrangement or composition with or for the benefit of its creditors; (4)(A) institutes or has instituted against it, by a regulator, supervisor or any similar official with primary insolvency, rehabilitative or regulatory jurisdiction over it in the jurisdiction of its incorporation or organisation or the jurisdiction of its head or home office, a proceeding seeking a judgment of insolvency or bankruptcy or any other relief under any bankruptcy or insolvency law or other similar law affecting creditors’ rights, or a petition is presented for its winding-up or liquidation by it or such regulator, supervisor or similar official, or

(B) has instituted against it a proceeding seeking a judgment of insolvency or bankruptcy or any other relief under any bankruptcy or insolvency law or other similar law affecting creditors’ rights, or a petition is presented for its winding-up or liquidation, and such proceeding or petition is instituted or presented by a person or entity not described in clause (A) above and either (I) results in a judgment of insolvency or bankruptcy or the entry of an order for relief or the making of an order for its winding-up or liquidation or (II) is not dismissed, discharged, stayed or restrained in each case within 15 days of the institution or presentation thereof; (5) has a resolution passed for its winding-up, official management or liquidation (other than pursuant to a consolidation, amalgamation or merger); (6) seeks or becomes subject to the appointment of an administrator, provisional liquidator, conservator, receiver, trustee, custodian or other similar official for it or for all or substantially all its assets; (7) has a secured party take possession of all or substantially all its assets or has a distress, execution, attachment, sequestration or other legal process levied, enforced or sued on or against all or substantially all its assets and such secured party maintains possession, or any such process is not dismissed, discharged, stayed or restrained, in each case within 15 days thereafter; (8) causes or is subject to any event with respect to it which, under the applicable laws of any jurisdiction, has an analogous effect to any of the events specified in clauses (l) to (7) above (inclusive); or (9) takes any action in furtherance of, or indicating its consent to, approval of, or acquiescence in, any of the foregoing acts; or

(viii) Merger Without Assumption. The party or any Credit Support Provider of such party consolidates or amalgamates with, or merges with or into, or transfers all or substantially all its assets to, or reorganises, reincorporates or reconstitutes into or as, another entity and, at the time of such consolidation, amalgamation, merger, transfer, reorganisation, reincorporation or reconstitution:―

(l) the resulting, surviving or transferee entity fails to assume all the obligations of such party or such Credit Support Provider under this Agreement or any Credit Support Document to which it or its predecessor was a party; or

(2) the benefits of any Credit Support Document fail to extend (without the consent of the other party) to the performance by such resulting, surviving or transferee entity of its obligations under this Agreement.

(b)Termination Events. The occurrence at any time with respect to a party or, if applicable, any Credit Support Provider of such party or any Specified Entity of such party of any event specified below constitutes (subject to Section 5(c)) an Illegality if the event is specified in clause (i) below, a Force Majeure Event if the event is specified in clause (ii) below, a Tax Event if the event is specified in clause (iii) below, a Tax Event Upon Merger if the event is specified in clause (iv) below, and, if specified to be applicable, a Credit Event Upon Merger if the event is specified pursuant to clause (v) below or an Additional Termination Event if the event is specified pursuant to clause (vi) below:―

(i)Illegality. After giving effect to any applicable provision, disruption fallback or remedy specified in, or pursuant to, the relevant Confirmation or elsewhere in this Agreement, due to an event or circumstance (other than any action taken by a party or, if applicable, any Credit Support Provider of such party) occurring after a Transaction is entered into, it becomes unlawful under any applicable law (including without limitation the laws of any country in which payment, delivery or compliance is required by either party or any Credit Support Provider, as the case may be), on any day, or it would be unlawful if the relevant payment, delivery or compliance were required on that day (in each case, other than as a result of a breach by the party of Section 4(b)):―

(1)for the Office through which such party (which will be the Affected Party) makes and receives payments or deliveries with respect to such Transaction to perform any absolute or contingent obligation to make a payment or delivery in respect of such Transaction, to receive a payment or delivery in respect of such Transaction or to comply with any other material provision of this Agreement relating to such Transaction; or

(2)for such party or any Credit Support Provider of such party (which will be the Affected Party) to perform any absolute or contingent obligation to make a payment or delivery which such party or Credit Support Provider has under any Credit Support Document relating to such Transaction, to receive a payment or delivery under such Credit Support Document or to comply with any other material provision of such Credit Support Document;

(ii)Force Majeure Event. After giving effect to any applicable provision, disruption fallback or remedy specified in, or pursuant to, the relevant Confirmation or elsewhere in this Agreement, by reason of force majeure or act of state occurring after a Transaction is entered into, on any day:―

(1)the Office through which such party (which will be the Affected Party) makes and receives payments or deliveries with respect to such Transaction is prevented from performing any absolute or contingent obligation to make a payment or delivery in respect of such Transaction, from receiving a payment or delivery in respect of such Transaction or from complying with any other material provision of this Agreement relating to such Transaction (or would be so prevented if such payment, delivery or compliance were required on that day), or it becomes impossible or impracticable for such Office so to perform, receive or comply (or it would be impossible or impracticable for such Office so to perform, receive or comply if such payment, delivery or compliance were required on that day); or

(2)such party or any Credit Support Provider of such party (which will be the Affected Party) is prevented from performing any absolute or contingent obligation to make a payment or delivery which such party or Credit Support Provider has under any Credit Support Document relating to such Transaction, from receiving a payment or delivery under such Credit Support Document or from complying with any other material provision of such Credit Support Document (or would be so prevented if such payment, delivery or compliance were required on that day), or it becomes impossible or impracticable for such party or Credit Support Provider so to perform, receive or comply (or it would be impossible or impracticable for such party or Credit Support Provider so to perform, receive or comply if such payment, delivery or compliance were required on that day), so long as the force majeure or act of state is beyond the control of such Office, such party or such Credit

Support Provider, as appropriate, and such Office, party or Credit Support Provider could not, after using all reasonable efforts (which will not require such party or Credit Support Provider to incur a loss, other than immaterial, incidental expenses), overcome such prevention, impossibility or impracticability;

(iii)Tax Event. Due to (1) any action taken by a taxing authority, or brought in a court of competent jurisdiction, after a Transaction is entered into (regardless of whether such action is taken or brought with respect to a party to this Agreement) or (2) a Change in Tax Law, the party (which will be the Affected Party) will, or there is a substantial likelihood that it will, on the next succeeding Scheduled Settlement Date (A) be required to pay to the other party an additional amount in respect of an Indemnifiable Tax under Section 2(d)(i)(4) (except in respect of interest under Section 9(h)) or (B) receive a payment from which an amount is required to be deducted or withheld for or on account of a Tax (except in respect of interest under Section 9(h)) and no additional amount is required to be paid in respect of such Tax under Section 2(d)(i)(4) (other than by reason of Section 2(d)(i)(4)(A) or (B));

(iv) Tax Event Upon Merger. The party (the “Burdened Party”) on the next succeeding Scheduled Settlement Date will either (1) be required to pay an additional amount in respect of an Indemnifiable Tax under Section 2(d)(i)(4) (except in respect of interest under Section 9(h)) or (2) receive a payment from which an amount has been deducted or withheld for or on account of any Tax in respect of which the other party is not required to pay an additional amount (other than by reason of Section 2(d)(i)(4)(A) or (B)), in either case as a result of a party consolidating or amalgamating with, or merging with or into, or transferring all or substantially all its assets (or any substantial part of the assets comprising the business conducted by it as of the date of this Master Agreement) to, or reorganising, reincorporating or reconstituting into or as, another entity (which will be the Affected Party) where such action does not constitute a Merger Without Assumption;

(v) Credit Event Upon Merger. If “Credit Event Upon Merger” is specified in the Schedule as applying to the party, a Designated Event (as defined below) occurs with respect to such party, any Credit Support Provider of such party or any applicable Specified Entity of such party (in each case, “X”) and such Designated Event does not constitute a Merger Without Assumption, and the creditworthiness of X or, if applicable, the successor, surviving or transferee entity of X, after taking into account any applicable Credit Support Document, is materially weaker immediately after the occurrence of such Designated Event than that of X immediately prior to the occurrence of such Designated Event (and, in any such event, such party or its successor, surviving or transferee entity, as appropriate, will be the Affected Party). A “Designated Event” with respect to X means that:―

(1)X consolidates or amalgamates with, or merges with or into, or transfers all or substantially all its assets (or any substantial part of the assets comprising the business conducted by X as of the date of this Master Agreement) to, or reorganises, reincorporates or reconstitutes into or as, another entity;

(2)any person, related group of persons or entity acquires directly or indirectly the beneficial ownership of (A) equity securities having the power to elect a majority of the board of directors (or its equivalent) of X or (B) any other ownership interest enabling it to exercise control of X; or

(3)X effects any substantial change in its capital structure by means of the issuance, incurrence or guarantee of debt or the issuance of (A) preferred stock or other securities convertible into or exchangeable for debt or preferred stock or (B) in the case of entities other than corporations, any other form of ownership interest; or

(vi) Additional Termination Event. If any “Additional Termination Event” is specified in the Schedule or any Confirmation as applying, the occurrence of such event (and, in such event, the Affected Party or Affected Parties will be as specified for such Additional Termination Event in the Schedule or such Confirmation).

(c)Hierarchy of Events.

(i)An event or circumstance that constitutes or gives rise to an Illegality or a Force Majeure Event will not, for so long as that is the case, also constitute or give rise to an Event of Default under Section 5(a)(i), 5(a)(ii)(1) or 5(a)(iii)

(1) insofar as such event or circumstance relates to the failure to make any payment or delivery or a failure to comply with any other material provision of this Agreement or a Credit Support Document, as the case may be.

(ii)Except in circumstances contemplated by clause (i) above, if an event or circumstance which would otherwise constitute or give rise to an Illegality or a Force Majeure Event also constitutes an Event of Default or any other Termination Event, it will be treated as an Event of Default or such other Termination Event, as the case may be, and will not constitute or give rise to an Illegality or a Force Majeure Event.

(iii)If an event or circumstance which would otherwise constitute or give rise to a Force Majeure Event also constitutes an Illegality, it will be treated as an Illegality, except as described in clause (ii) above, and not a Force Majeure Event.

(d)Deferral of Payments and Deliveries During Waiting Period. If an Illegality or a Force Majeure Event has occurred and is continuing with respect to a Transaction, each payment or delivery which would otherwise be required to be made under that Transaction will be deferred to, and will not be due until:―

(i)the first Local Business Day or, in the case of a delivery, the first Local Delivery Day (or the first day that would have been a Local Business Day or Local Delivery Day, as appropriate, but for the occurrence of the event or circumstance constituting or giving rise to that Illegality or Force Majeure Event) following the end of any applicable Waiting Period in respect of that Illegality or Force Majeure Event, as the case may be; or

(ii)if earlier, the date on which the event or circumstance constituting or giving rise to that Illegality or Force Majeure Event ceases to exist or, if such date is not a Local Business Day or, in the case of a delivery, a Local Delivery Day, the first following day that is a Local Business Day or Local Delivery Day, as appropriate.

(e)Inability of Head or Home Office to Perform Obligations of Branch. If (i) an Illegality or a Force Majeure Event occurs under Section 5(b)(i)(1) or 5(b)(ii)(1) and the relevant Office is not the Affected Party’s head or home office, (ii) Section 10(a) applies, (iii) the other party seeks performance of the relevant obligation or compliance with the relevant provision by the Affected Party’s head or home office and (iv) the Affected Party’s head or home office fails so to perform or comply due to the occurrence of an event or circumstance which would, if that head or home office were the Office through which the Affected Party makes and receives payments and deliveries with respect to the relevant Transaction, constitute or give rise to an Illegality or a Force Majeure Event, and such failure would otherwise constitute an Event of Default under Section 5(a)(i) or 5(a)(iii)(1) with respect to such party, then, for so long as the relevant event or circumstance continues to exist with respect to both the Office referred to in Section 5(b)(i)(1) or 5(b)(ii)(1), as the case may be, and the Affected Party’s head or home office, such failure will not constitute an Event of Default under Section 5(a)(i) or 5(a)(iii)(1).

6.Early Termination; Close-Out Netting

(a)Right to Terminate Following Event of Default. If at any time an Event of Default with respect to a party (the “Defaulting Party”) has occurred and is then continuing, the other party (the “Non-defaulting Party”) may, by not more than 20 days notice to the Defaulting Party specifying the relevant Event of Default, designate a day not earlier than the day such notice is effective as an Early Termination Date in respect of all outstanding Transactions. If, however, “Automatic Early Termination” is specified in the Schedule as applying to a party, then an Early Termination Date in respect of all outstanding Transactions will occur immediately upon the occurrence with respect to such party of an Event of Default specified in Section 5(a)(vii)(1), (3), (5), (6) or, to the extent analogous thereto, (8), and as of the time immediately preceding the institution of the relevant proceeding or the presentation of the relevant petition upon the occurrence with respect to such party of an Event of Default specified in Section 5(a)(vii)(4) or, to the extent analogous thereto, (8).

(b)Right to Terminate Following Termination Event.

(i)Notice. If a Termination Event other than a Force Majeure Event occurs, an Affected Party will, promptly upon becoming aware of it, notify the other party, specifying the nature of that Termination Event and each Affected Transaction, and will also give the other party such other information about that Termination Event as the other party may reasonably require. If a Force Majeure Event occurs, each party will, promptly upon becoming aware of it, use all reasonable efforts to notify the other party, specifying the nature of that Force Majeure Event, and will also give the other party such other information about that Force Majeure Event as the other party may reasonably require.

(ii)Transfer to Avoid Termination Event. If a Tax Event occurs and there is only one Affected Party, or if a Tax Event Upon Merger occurs and the Burdened Party is the Affected Party, the Affected Party will, as a condition to its right to designate an Early Termination Date under Section 6(b)(iv), use all reasonable efforts (which will not require such party to incur a loss, other than immaterial, incidental expenses) to transfer within 20 days after it gives notice under Section 6(b)(i) all its rights and obligations under this

Agreement in respect of the Affected Transactions to another of its Offices or Affiliates so that such Termination Event ceases to exist.

If the Affected Party is not able to make such a transfer it will give notice to the other party to that effect within such 20 day period, whereupon the other party may effect such a transfer within 30 days after the notice is given under Section 6(b)(i).

Any such transfer by a party under this Section 6(b)(ii) will be subject to and conditional upon the prior written consent of the other party, which consent will not be withheld if such other party’s policies in effect at such time would permit it to enter into transactions with the transferee on the terms proposed.

(iii)Two Affected Parties. If a Tax Event occurs and there are two Affected Parties, each party will use all reasonable efforts to reach agreement within 30 days after notice of such occurrence is given under Section 6(b)(i) to avoid that Termination Event.

(iv) Right to Terminate.

(1)If:―

(A) a transfer under Section 6(b)(ii) or an agreement under Section 6(b)(iii), as the case may be, has not been effected with respect to all Affected Transactions within 30 days after an Affected Party gives notice under Section 6(b)(i); or

(B) a Credit Event Upon Merger or an Additional Termination Event occurs, or a Tax Event Upon Merger occurs and the Burdened Party is not the Affected Party, the Burdened Party in the case of a Tax Event Upon Merger, any Affected Party in the case of a Tax Event or an Additional Termination Event if there are two Affected Parties, or the Non- affected Party in the case of a Credit Event Upon Merger or an Additional Termination Event if there is only one Affected Party may, if the relevant Termination Event is then continuing, by not more than 20 days notice to the other party, designate a day not earlier than the day such notice is effective as an Early Termination Date in respect of all Affected Transactions.

(2)If at any time an Illegality or a Force Majeure Event has occurred and is then continuing and any applicable Waiting Period has expired:―

(A) Subject to clause (B) below, either party may, by not more than 20 days notice to the other party, designate (I) a day not earlier than the day on which such notice becomes effective as an Early Termination Date in respect of all Affected Transactions or (II) by specifying in that notice the Affected Transactions in respect of which it is designating the relevant day as an Early Termination Date, a day not earlier than two Local Business Days following the day on which such notice becomes effective as an Early Termination Date in respect of less than all Affected Transactions. Upon receipt of a notice designating an Early Termination Date in respect of less than all Affected Transactions, the other party may, by notice to the designating party, if such notice is effective on or before the day so designated, designate that same day as an Early Termination Date in respect of any or all other Affected Transactions.

(B) An Affected Party (if the Illegality or Force Majeure Event relates to performance by such party or any Credit Support Provider of such party of an obligation to make any payment or delivery under, or to compliance with any other material provision of, the relevant Credit Support Document) will only have the right to designate an Early Termination Date under Section 6(b)(iv)(2)(A) as a result of an Illegality under Section 5(b)(i)(2) or a Force Majeure Event under Section 5(b)(ii)(2) following the prior designation by the other party of an Early Termination Date, pursuant to Section 6(b)(iv)(2)(A), in respect of less than all Affected Transactions.

(c)Effect of Designation.

(i)If notice designating an Early Termination Date is given under Section 6(a) or 6(b), the Early Termination Date will occur on the date so designated, whether or not the relevant Event of Default or Termination Event is then continuing.

(ii)Upon the occurrence or effective designation of an Early Termination Date, no further payments or deliveries under Section 2(a)(i) or 9(h)(i) in respect of the Terminated Transactions will be required to be made, but without prejudice to the other provisions of this Agreement. The amount, if any, payable in respect of an Early Termination Date will be determined pursuant to Sections 6(e) and 9(h)(ii).

(d)Calculations; Payment Date.

(i)Statement. On or as soon as reasonably practicable following the occurrence of an Early

Termination Date, each party will make the calculations on its part, if any, contemplated by Section 6(e) and will provide to the other party a statement (l) showing, in reasonable detail, such calculations (including any quotations, market data or information from internal sources used in making such calculations),

(2)specifying (except where there are two Affected Parties) any Early Termination Amount payable and

(3)giving details of the relevant account to which any amount payable to it is to be paid. In the absence of written confirmation from the source of a quotation or market data obtained in determining a Close-out

Amount, the records of the party obtaining such quotation or market data will be conclusive evidence of the existence and accuracy of such quotation or market data.

(ii)Payment Date. An Early Termination Amount due in respect of any Early Termination Date will, together with any amount of interest payable pursuant to Section 9(h)(ii)(2), be payable (1) on the day on which notice of the amount payable is effective in the case of an Early Termination Date which is designated or occurs as a result of an Event of Default and (2) on the day which is two Local Business Days after the day on which notice of the amount payable is effective (or, if there are two Affected Parties, after the day on which the statement provided pursuant to clause (i) above by the second party to provide such a statement is effective) in the case of an Early Termination Date which is designated as a result of a Termination Event.

(e)Payments on Early Termination. If an Early Termination Date occurs, the amount, if any, payable in respect of that Early Termination Date (the “Early Termination Amount”) will be determined pursuant to this Section 6(e) and will be subject to Section 6(f).

(i)Events of Default. If the Early Termination Date results from an Event of Default, the Early Termination Amount will be an amount equal to (1) the sum of (A) the Termination Currency Equivalent of the Close-out Amount or Close-out Amounts (whether positive or negative) determined by the Non- defaulting Party for each Terminated Transaction or group of Terminated Transactions, as the case may be, and (B) the Termination Currency Equivalent of the Unpaid Amounts owing to the Non-defaulting Party less

(2) the Termination Currency Equivalent of the Unpaid Amounts owing to the Defaulting Party. If the Early Termination Amount is a positive number, the Defaulting Party will pay it to the Non-defaulting Party; if it is a negative number, the Non-defaulting Party will pay the absolute value of the Early Termination Amount to the Defaulting Party.

(ii)Termination Events. If the Early Termination Date results from a Termination Event:―

(1)One Affected Party. Subject to clause (3) below, if there is one Affected Party, the Early Termination Amount will be determined in accordance with Section 6(e)(i), except that references to the Defaulting Party and to the Non-defaulting Party will be deemed to be references to the Affected Party and to the Non-affected Party, respectively.

(2)Two Affected Parties. Subject to clause (3) below, if there are two Affected Parties, each party will determine an amount equal to the Termination Currency Equivalent of the sum of the Close-out Amount or Close-out Amounts (whether positive or negative) for each Terminated Transaction or group of Terminated Transactions, as the case may be, and the Early Termination Amount will be an amount equal to (A) the sum of (I) one-half of the difference between the higher amount so determined (by party “X”) and the lower amount so determined (by party “Y”) and

(II) the Termination Currency Equivalent of the Unpaid Amounts owing to X less (B) the Termination Currency Equivalent of the Unpaid Amounts owing to Y. If the Early Termination Amount is a positive number, Y will pay it to X; if it is a negative number, X will pay the absolute value of the Early Termination Amount to Y.

(3)Mid-Market Events. If that Termination Event is an Illegality or a Force Majeure Event, then the Early Termination Amount will be determined in accordance with clause (1) or (2) above, as appropriate, except that, for the purpose of determining a Close-out Amount or Close-out Amounts, the Determining Party will:―

(A) if obtaining quotations from one or more third parties (or from any of the Determining Party’s Affiliates), ask each third party or Affiliate (I) not to take account of the current creditworthiness of the Determining Party or any existing Credit Support Document and (II) to provide mid-market quotations; and

(B) in any other case, use mid-market values without regard to the creditworthiness of the Determining Party.

(iii)Adjustment for Bankruptcy. In circumstances where an Early Termination Date occurs because Automatic Early Termination applies in respect of a party, the Early Termination Amount will be subject to such adjustments as are appropriate and permitted by applicable law to reflect any payments or deliveries made by one party to the other under this Agreement (and retained by such other party) during the period from the relevant Early Termination Date to the date for payment determined under Section 6(d)(ii).

(iv) Adjustment for Illegality or Force Majeure Event. The failure by a party or any Credit Support Provider of such party to pay, when due, any Early Termination Amount will not constitute an Event of Default under Section 5(a)(i) or 5(a)(iii)(1) if such failure is due to the occurrence of an event or circumstance which would, if it occurred with respect to payment, delivery or compliance related to a Transaction, constitute or give rise to an Illegality or a Force Majeure Event. Such amount will (1) accrue interest and otherwise be treated as an Unpaid Amount owing to the other party if subsequently an Early Termination Date results from an Event of Default, a Credit Event Upon Merger or an Additional Termination Event in respect of which all outstanding Transactions are Affected Transactions and

(2) otherwise accrue interest in accordance with Section 9(h)(ii)(2).

(v) Pre-Estimate. The parties agree that an amount recoverable under this Section 6(e) is a reasonable pre-estimate of loss and not a penalty. Such amount is payable for the loss of bargain and the loss of protection against future risks, and, except as otherwise provided in this Agreement, neither party will be entitled to recover any additional damages as a consequence of the termination of the Terminated Transactions.

(f) Set-Off. Any Early Termination Amount payable to one party (the “Payee”) by the other party (the “Payer”), in circumstances where there is a Defaulting Party or where there is one Affected Party in the case where either a Credit Event Upon Merger has occurred or any other Termination Event in respect of which all outstanding

Transactions are Affected Transactions has occurred, will, at the option of the Non-defaulting Party or the Non-affected Party, as the case may be (“X”) (and without prior notice to the Defaulting Party or the Affected Party, as the case may be), be reduced by its set-off against any other amounts (“Other Amounts”) payable by the Payee to the Payer (whether or not arising under this Agreement, matured or contingent and irrespective of the currency, place of payment or place of booking of the obligation). To the extent that any Other Amounts are so set off, those Other Amounts will be discharged promptly and in all respects. X will give notice to the other party of any set-off effected under this Section 6(f).

For this purpose, either the Early Termination Amount or the Other Amounts (or the relevant portion of such amounts) may be converted by X into the currency in which the other is denominated at the rate of exchange at which such party would be able, in good faith and using commercially reasonable procedures, to purchase the relevant amount of such currency.

If an obligation is unascertained, X may in good faith estimate that obligation and set off in respect of the estimate, subject to the relevant party accounting to the other when the obligation is ascertained.

Nothing in this Section 6(f) will be effective to create a charge or other security interest. This Section 6(f) will be without prejudice and in addition to any right of set-off, offset, combination of accounts, lien, right of retention or withholding or similar right or requirement to which any party is at any time otherwise entitled or subject (whether by operation of law, contract or otherwise).

7.Transfer

Subject to Section 6(b)(ii) and to the extent permitted by applicable law, neither this Agreement nor any interest or obligation in or under this Agreement may be transferred (whether by way of security or otherwise) by either party without the prior written consent of the other party, except that:―

(a)a party may make such a transfer of this Agreement pursuant to a consolidation or amalgamation with, or merger with or into, or transfer of all or substantially all its assets to, another entity (but without prejudice to any other right or remedy under this Agreement); and

(b)a party may make such a transfer of all or any part of its interest in any Early Termination Amount payable to it by a Defaulting Party, together with any amounts payable on or with respect to that interest and any other rights associated with that interest pursuant to Sections 8, 9(h) and 11.

Any purported transfer that is not in compliance with this Section 7 will be void.

8.Contractual Currency

(a)Payment in the Contractual Currency. Each payment under this Agreement will be made in the relevant currency specified in this Agreement for that payment (the “Contractual Currency”). To the extent permitted by applicable law, any obligation to make payments under this Agreement in the Contractual Currency will not be discharged or satisfied by any tender in any currency other than the Contractual Currency, except to the extent such tender results in the actual receipt by the party to which payment is owed, acting in good faith and using commercially reasonable procedures in converting the currency so tendered into the Contractual Currency, of the full amount in the Contractual Currency of all amounts payable in respect of this Agreement. If for any reason the amount in the Contractual Currency so received falls short of the amount in the Contractual Currency payable in respect of this Agreement, the party required to make the payment will, to the extent permitted by applicable law, immediately pay such additional amount in the Contractual Currency as may be necessary to compensate for the shortfall. If for any reason the amount in the Contractual Currency so received exceeds the amount in the Contractual Currency payable in respect of this Agreement, the party receiving the payment will refund promptly the amount of such excess.

(b)Judgments. To the extent permitted by applicable law, if any judgment or order expressed in a currency other than the Contractual Currency is rendered (i) for the payment of any amount owing in respect of this Agreement, (ii) for the payment of any amount relating to any early termination in respect of this Agreement or (iii) in respect of a judgment or order of another court for the payment of any amount described in clause (i) or (ii) above, the party seeking recovery, after recovery in full of the aggregate amount to which such party is entitled pursuant to the judgment or order, will be entitled to receive immediately from the other party the amount of any shortfall of the Contractual Currency received by such party as a consequence of sums paid in such other currency and will refund promptly to the other party any excess of the Contractual Currency received by such party as a consequence of sums paid in such other currency if such shortfall or such excess arises or results from any variation between the rate of exchange at which the Contractual Currency is converted into the currency of the judgment or order for the purpose of such judgment or order and the rate of exchange at which such party is able, acting in good faith and using commercially reasonable procedures in converting the currency received into the Contractual Currency, to purchase the Contractual Currency with the amount of the currency of the judgment or order actually received by such party.

(c)Separate Indemnities. To the extent permitted by applicable law, the indemnities in this Section 8 constitute separate and independent obligations from the other obligations in this Agreement, will be enforceable as separate and independent causes of action, will apply notwithstanding any indulgence granted by the party to which any payment is owed and will not be affected by judgment being obtained or claim or proof being made for any other sums payable in respect of this Agreement.

(d)Evidence of Loss. For the purpose of this Section 8, it will be sufficient for a party to demonstrate that it would have suffered a loss had an actual exchange or purchase been made.

9.Miscellaneous

(a)Entire Agreement. This Agreement constitutes the entire agreement and understanding of the parties with respect to its subject matter. Each of the parties acknowledges that in entering into this Agreement it has not relied on any oral or written representation, warranty or other assurance (except as provided for or referred to in this Agreement) and waives all rights and remedies which might otherwise be available to it in respect thereof, except that nothing in this Agreement will limit or exclude any liability of a party for fraud.

(b)Amendments. An amendment, modification or waiver in respect of this Agreement will only be effective if in writing (including a writing evidenced by a facsimile transmission) and executed by each of the parties or confirmed by an exchange of telexes or by an exchange of electronic messages on an electronic messaging system.

(c)Survival of Obligations. Without prejudice to Sections 2(a)(iii) and 6(c)(ii), the obligations of the parties under this Agreement will survive the termination of any Transaction.

(d)Remedies Cumulative. Except as provided in this Agreement, the rights, powers, remedies and privileges provided in this Agreement are cumulative and not exclusive of any rights, powers, remedies and privileges provided by law.

(e)Counterparts and Confirmations.

(i)This Agreement (and each amendment, modification and waiver in respect of it) may be executed and delivered in counterparts (including by facsimile transmission and by electronic messaging system), each of which will be deemed an original.

(ii)The parties intend that they are legally bound by the terms of each Transaction from the moment they agree to those terms (whether orally or otherwise). A Confirmation will be entered into as soon as practicable and may be executed and delivered in counterparts (including by facsimile transmission) or be created by an exchange of telexes, by an exchange of electronic messages on an electronic messaging system or by an exchange of e-mails, which in each case will be sufficient for all purposes to evidence a binding supplement to this Agreement. The parties will specify therein or through another effective means that any such counterpart, telex, electronic message or e-mail constitutes a Confirmation.

(f) No Waiver of Rights. A failure or delay in exercising any right, power or privilege in respect of this Agreement will not be presumed to operate as a waiver, and a single or partial exercise of any right, power or privilege will not be presumed to preclude any subsequent or further exercise, of that right, power or privilege or the exercise of any other right, power or privilege.

(g) Headings. The headings used in this Agreement are for convenience of reference only and are not to affect the construction of or to be taken into consideration in interpreting this Agreement.

(h) Interest and Compensation.

(i)Prior to Early Termination. Prior to the occurrence or effective designation of an Early Termination Date in respect of the relevant Transaction:―

(1)Interest on Defaulted Payments. If a party defaults in the performance of any payment obligation, it will, to the extent permitted by applicable law and subject to Section 6(c), pay interest (before as well as after judgment) on the overdue amount to the other party on demand in the same currency as the overdue amount, for the period from (and including) the original due date for payment to (but excluding) the date of actual payment (and excluding any period in respect of which interest or compensation in respect of the overdue amount is due pursuant to clause (3)(B) or (C) below), at the Default Rate.

(2)Compensation for Defaulted Deliveries. If a party defaults in the performance of any obligation required to be settled by delivery, it will on demand (A) compensate the other party to the extent provided for in the relevant Confirmation or elsewhere in this Agreement and (B) unless otherwise provided in the relevant Confirmation or elsewhere in this Agreement, to the extent permitted by applicable law and subject to Section 6(c), pay to the other party interest (before as well as after judgment) on an amount equal to the fair market value of that which was required to be delivered in the same currency as that amount, for the period from (and including) the originally scheduled date for delivery to (but excluding) the date of actual delivery (and excluding any period in respect of which interest or compensation in respect of that amount is due pursuant to clause (4) below), at the Default Rate. The fair market value of any obligation referred to above will be determined as of the originally scheduled date for delivery, in good faith and using commercially reasonable procedures, by the party that was entitled to take delivery.

(3)Interest on Deferred Payments. If:―

(A) a party does not pay any amount that, but for Section 2(a)(iii), would have been payable, it will, to the extent permitted by applicable law and subject to Section 6(c) and clauses (B) and (C) below, pay interest (before as well as after judgment) on that amount to the other party on demand (after such amount becomes payable) in the same currency as that amount, for the period from (and including) the date the amount would, but for Section 2(a)(iii), have been payable to (but excluding) the date the amount actually becomes payable, at the Applicable Deferral Rate;

(B) a payment is deferred pursuant to Section 5(d), the party which would otherwise have been required to make that payment will, to the extent permitted by applicable law, subject to Section 6(c) and for so long as no Event of Default or Potential Event of Default with respect to that party has occurred and is continuing, pay interest (before as well as after judgment) on the amount of the deferred payment to the other party on demand (after such amount becomes payable) in the same currency as the deferred payment, for the period from (and including) the date the amount would, but for Section 5(d), have been payable to (but excluding) the earlier of the date the payment is no longer deferred pursuant to Section 5(d) and the date during the deferral period upon which an Event of Default or Potential Event of Default with respect to that party occurs, at the Applicable Deferral Rate; or

(C) a party fails to make any payment due to the occurrence of an Illegality or a Force Majeure Event (after giving effect to any deferral period contemplated by clause (B) above), it will, to the extent permitted by applicable law, subject to Section 6(c) and for so long as the event or circumstance giving rise to that Illegality or Force Majeure Event continues and no Event of Default or Potential Event of Default with respect to that party has occurred and is continuing, pay interest (before as well as after judgment) on the overdue amount to the other party on demand in the same currency as the overdue amount, for the period from (and including) the date the party fails to make the payment due to the occurrence of the relevant Illegality or Force Majeure Event (or, if later, the date the payment is no longer deferred pursuant to Section 5(d)) to (but excluding) the earlier of the date the event or circumstance giving rise to that Illegality or Force Majeure Event ceases to exist and the date during the period upon which an Event of Default or Potential Event of Default with respect to that party occurs (and excluding any period in respect of which interest or compensation in respect of the overdue amount is due pursuant to clause (B) above), at the Applicable Deferral Rate.

(4)Compensation for Deferred Deliveries. If:―

(A) a party does not perform any obligation that, but for Section 2(a)(iii), would have been required to be settled by delivery;

(B) a delivery is deferred pursuant to Section 5(d); or

(C) a party fails to make a delivery due to the occurrence of an Illegality or a Force Majeure Event at a time when any applicable Waiting Period has expired, the party required (or that would otherwise have been required) to make the delivery will, to the extent permitted by applicable law and subject to Section 6(c), compensate and pay interest to the other party on demand (after, in the case of clauses (A) and (B) above, such delivery is required) if and to the extent provided for in the relevant Confirmation or elsewhere in this Agreement.

(ii)Early Termination. Upon the occurrence or effective designation of an Early Termination Date in respect of a Transaction:―

(1)Unpaid Amounts. For the purpose of determining an Unpaid Amount in respect of the relevant Transaction, and to the extent permitted by applicable law, interest will accrue on the amount of any payment obligation or the amount equal to the fair market value of any obligation required to be settled by delivery included in such determination in the same currency as that amount, for the period from (and including) the date the relevant obligation was (or would have been but for Section 2(a)(iii) or 5(d)) required to have been performed to (but excluding) the relevant Early Termination Date, at the Applicable Close-out Rate.

(2)Interest on Early Termination Amounts. If an Early Termination Amount is due in respect of such Early Termination Date, that amount will, to the extent permitted by applicable law, be paid together with interest (before as well as after judgment) on that amount in the Termination Currency, for the period from (and including) such Early Termination Date to (but excluding) the date the amount is paid, at the Applicable Close-out Rate.

(iii)Interest Calculation. Any interest pursuant to this Section 9(h) will be calculated on the basis of daily compounding and the actual number of days elapsed.

10.Offices; Multibranch Parties

(a)If Section 10(a) is specified in the Schedule as applying, each party that enters into a Transaction through an Office other than its head or home office represents to and agrees with the other party that, notwithstanding the place of booking or its jurisdiction of incorporation or organisation, its obligations are the same in terms of recourse against it as if it had entered into the Transaction through its head or home office, except that a party will not have recourse to the head or home office of the other party in respect of any payment or delivery deferred pursuant to Section 5(d) for so long as the payment or delivery is so deferred. This representation and agreement will be deemed to be repeated by each party on each date on which the parties enter into a Transaction.

(b)If a party is specified as a Multibranch Party in the Schedule, such party may, subject to clause (c) below, enter into a Transaction through, book a Transaction in and make and receive payments and deliveries with respect to a Transaction through any Office listed in respect of that party in the Schedule (but not any other Office unless otherwise agreed by the parties in writing).

(c)The Office through which a party enters into a Transaction will be the Office specified for that party in the relevant Confirmation or as otherwise agreed by the parties in writing, and, if an Office for that party is not specified in the Confirmation or otherwise agreed by the parties in writing, its head or home office. Unless the parties otherwise agree in writing, the Office through which a party enters into a Transaction will also be the Office in which it books the Transaction and the Office through which it makes and receives payments and deliveries with respect to the Transaction. Subject to Section 6(b)(ii), neither party may change the Office in which it books the Transaction or the Office through which it makes and receives payments or deliveries with respect to a Transaction without the prior written consent of the other party.

11.Expenses

A Defaulting Party will on demand indemnify and hold harmless the other party for and against all reasonable out-of- pocket expenses, including legal fees, execution fees and Stamp Tax, incurred by such other party by reason of the enforcement and protection of its rights under this Agreement or any Credit Support Document to which the Defaulting Party is a party or by reason of the early termination of any Transaction, including, but not limited to, costs of collection.

12.Notices

(a)Effectiveness. Any notice or other communication in respect of this Agreement may be given in any manner described below (except that a notice or other communication under Section 5 or 6 may not be given by electronic messaging system or e-mail) to the address or number or in accordance with the electronic messaging system or e-mail details provided (see the Schedule) and will be deemed effective as indicated:―

(i)if in writing and delivered in person or by courier, on the date it is delivered;

(ii)if sent by telex, on the date the recipient’s answerback is received;

(iii)if sent by facsimile transmission, on the date it is received by a responsible employee of the recipient in legible form (it being agreed that the burden of proving receipt will be on the sender and will not be met by a transmission report generated by the sender’s facsimile machine);

(iv)if sent by certified or registered mail (airmail, if overseas) or the equivalent (return receipt requested), on the date it is delivered or its delivery is attempted;

(v)if sent by electronic messaging system, on the date it is received; or

(vi) if sent by e-mail, on the date it is delivered, unless the date of that delivery (or attempted delivery) or that receipt, as applicable, is not a Local Business Day or that communication is delivered (or attempted) or received, as applicable, after the close of business on a Local Business Day, in which case that communication will be deemed given and effective on the first following day that is a Local Business Day.

(b)Change of Details. Either party may by notice to the other change the address, telex or facsimile number or electronic messaging system or e-mail details at which notices or other communications are to be given to it.

13.Governing Law and Jurisdiction

(a)Governing Law. This Agreement will be governed by and construed in accordance with the law specified in the Schedule.

(b)Jurisdiction. With respect to any suit, action or proceedings relating to any dispute arising out of or in connection with this Agreement (“Proceedings”), each party irrevocably:―

(i)submits:―

(1)if this Agreement is expressed to be governed by English law, to (A) the non-exclusive jurisdiction of the English courts if the Proceedings do not involve a Convention Court and (B) the exclusive jurisdiction of the English courts if the Proceedings do involve a Convention Court; or

(2)if this Agreement is expressed to be governed by the laws of the State of New York, to the non-exclusive jurisdiction of the courts of the State of New York and the United States District Court located in the Borough of Manhattan in New York City;

(ii)waives any objection which it may have at any time to the laying of venue of any Proceedings brought in any such court, waives any claim that such Proceedings have been brought in an inconvenient forum and further waives the right to object, with respect to such Proceedings, that such court does not have any jurisdiction over such party; and

(iii)agrees, to the extent permitted by applicable law, that the bringing of Proceedings in any one or more jurisdictions will not preclude the bringing of Proceedings in any other jurisdiction.

(c)Service of Process. Each party irrevocably appoints the Process Agent, if any, specified opposite its name in the Schedule to receive, for it and on its behalf, service of process in any Proceedings. If for any reason any party’s Process Agent is unable to act as such, such party will promptly notify the other party and within 30 days appoint a substitute process agent acceptable to the other party. The parties irrevocably consent to service of process given in the manner provided for notices in Section 12(a)(i), 12(a)(iii) or 12(a)(iv). Nothing in this Agreement will affect the right of either party to serve process in any other manner permitted by applicable law.

(d)Waiver of Immunities. Each party irrevocably waives, to the extent permitted by applicable law, with respect to itself and its revenues and assets (irrespective of their use or intended use), all immunity on the grounds of sovereignty or other similar grounds from (i) suit, (ii) jurisdiction of any court, (iii) relief by way of injunction or order for specific performance or recovery of property, (iv) attachment of its assets (whether before or after judgment) and (v) execution or enforcement of any judgment to which it or its revenues or assets might otherwise be entitled in any Proceedings in the courts of any jurisdiction and irrevocably agrees, to the extent permitted by applicable law, that it will not claim any such immunity in any Proceedings.

14.Definitions

As used in this Agreement:―

“Additional Representation” has the meaning specified in Section 3.

“Additional Termination Event” has the meaning specified in Section 5(b).

“Affected Party” has the meaning specified in Section 5(b).

“Affected Transactions” means (a) with respect to any Termination Event consisting of an Illegality, Force Majeure Event, Tax Event or Tax Event Upon Merger, all Transactions affected by the occurrence of such Termination Event (which, in the case of an Illegality under Section 5(b)(i)(2) or a Force Majeure Event under Section 5(b)(ii)(2), means all Transactions unless the relevant Credit Support Document references only certain Transactions, in which case those Transactions and, if the relevant Credit Support Document constitutes a Confirmation for a Transaction, that Transaction) and (b) with respect to any other Termination Event, all Transactions.

“Affiliate” means, subject to the Schedule, in relation to any person, any entity controlled, directly or indirectly, by the person, any entity that controls, directly or indirectly, the person or any entity directly or indirectly under common control with the person. For this purpose, “control” of any entity or person means ownership of a majority of the voting power of the entity or person.

“Agreement” has the meaning specified in Section 1(c).

“Applicable Close-out Rate” means:―

(a)in respect of the determination of an Unpaid Amount:―

(i)in respect of obligations payable or deliverable (or which would have been but for Section 2(a)(iii))

by a Defaulting Party, the Default Rate;

(ii)in respect of obligations payable or deliverable (or which would have been but for Section 2(a)(iii))

by a Non-defaulting Party, the Non-default Rate;

(iii)in respect of obligations deferred pursuant to Section 5(d), if there is no Defaulting Party and for solong as the deferral period continues, the Applicable Deferral Rate; and

(iv)in all other cases following the occurrence of a Termination Event (except where interest accrues pursuant to clause (iii) above), the Applicable Deferral Rate; and

(b)in respect of an Early Termination Amount:―

(i)for the period from (and including) the relevant Early Termination Date to (but excluding) the date (determined in accordance with Section 6(d)(ii)) on which that amount is payable:―

(1)if the Early Termination Amount is payable by a Defaulting Party, the Default Rate;

(2)if the Early Termination Amount is payable by a Non-defaulting Party, the Non-default Rate; and

(3)in all other cases, the Applicable Deferral Rate; and

(ii)for the period from (and including) the date (determined in accordance with Section 6(d)(ii)) on which that amount is payable to (but excluding) the date of actual payment:―

(1)if a party fails to pay the Early Termination Amount due to the occurrence of an event or circumstance which would, if it occurred with respect to a payment or delivery under a Transaction, constitute or give rise to an Illegality or a Force Majeure Event, and for so long as the Early Termination Amount remains unpaid due to the continuing existence of such event or circumstance, the Applicable Deferral Rate;

(2)if the Early Termination Amount is payable by a Defaulting Party (but excluding any period in respect of which clause (1) above applies), the Default Rate;

(3)if the Early Termination Amount is payable by a Non-defaulting Party (but excluding any period in respect of which clause (1) above applies), the Non-default Rate; and

(4)in all other cases, the Termination Rate.

“Applicable Deferral Rate” means:―

(a)for the purpose of Section 9(h)(i)(3)(A), the rate certified by the relevant payer to be a rate offered to the payer by a major bank in a relevant interbank market for overnight deposits in the applicable currency, such bank to be selected in good faith by the payer for the purpose of obtaining a representative rate that will reasonably reflect conditions prevailing at the time in that relevant market;

(b)for purposes of Section 9(h)(i)(3)(B) and clause (a)(iii) of the definition of Applicable Close-out Rate, the rate certified by the relevant payer to be a rate offered to prime banks by a major bank in a relevant interbank market for overnight deposits in the applicable currency, such bank to be selected in good faith by the payer after consultation with the other party, if practicable, for the purpose of obtaining a representative rate that will reasonably reflect conditions prevailing at the time in that relevant market; and

(c)for purposes of Section 9(h)(i)(3)(C) and clauses (a)(iv), (b)(i)(3) and (b)(ii)(1) of the definition of Applicable Close-out Rate, a rate equal to the arithmetic mean of the rate determined pursuant to clause (a) above and a rate per annum equal to the cost (without proof or evidence of any actual cost) to the relevant payee (as certified by it) if it were to fund or of funding the relevant amount.

“Automatic Early Termination” has the meaning specified in Section 6(a).

“Burdened Party” has the meaning specified in Section 5(b)(iv).

“Change in Tax Law” means the enactment, promulgation, execution or ratification of, or any change in or amendment to, any law (or in the application or official interpretation of any law) that occurs after the parties enter into the relevant Transaction.

“Close-out Amount” means, with respect to each Terminated Transaction or each group of Terminated Transactions and a Determining Party, the amount of the losses or costs of the Determining Party that are or would be incurred under then prevailing circumstances (expressed as a positive number) or gains of the Determining Party that are or

would be realised under then prevailing circumstances (expressed as a negative number) in replacing, or in providing for the Determining Party the economic equivalent of, (a) the material terms of that Terminated Transaction or group of Terminated Transactions, including the payments and deliveries by the parties under Section 2(a)(i) in respect of that Terminated Transaction or group of Terminated Transactions that would, but for the occurrence of the relevant Early Termination Date, have been required after that date (assuming satisfaction of the conditions precedent in

Section 2(a)(iii)) and (b) the option rights of the parties in respect of that Terminated Transaction or group of Terminated Transactions.

Any Close-out Amount will be determined by the Determining Party (or its agent), which will act in good faith and use commercially reasonable procedures in order to produce a commercially reasonable result. The Determining Party may determine a Close-out Amount for any group of Terminated Transactions or any individual Terminated Transaction but, in the aggregate, for not less than all Terminated Transactions. Each Close-out Amount will be determined as of the Early Termination Date or, if that would not be commercially reasonable, as of the date or dates following the Early Termination Date as would be commercially reasonable.

Unpaid Amounts in respect of a Terminated Transaction or group of Terminated Transactions and legal fees and out-of-pocket expenses referred to in Section 11 are to be excluded in all determinations of Close-out Amounts.

In determining a Close-out Amount, the Determining Party may consider any relevant information, including, without limitation, one or more of the following types of information:―

(i)quotations (either firm or indicative) for replacement transactions supplied by one or more third parties that may take into account the creditworthiness of the Determining Party at the time the quotation is provided and the terms of any relevant documentation, including credit support documentation, between the Determining Party and the third party providing the quotation;

(ii)information consisting of relevant market data in the relevant market supplied by one or more third parties including, without limitation, relevant rates, prices, yields, yield curves, volatilities, spreads, correlations or other relevant market data in the relevant market; or

(iii)information of the types described in clause (i) or (ii) above from internal sources (including any of the Determining Party’s Affiliates) if that information is of the same type used by the Determining Party in the regular course of its business for the valuation of similar transactions.

The Determining Party will consider, taking into account the standards and procedures described in this definition, quotations pursuant to clause (i) above or relevant market data pursuant to clause (ii) above unless the Determining Party reasonably believes in good faith that such quotations or relevant market data are not readily available or would produce a result that would not satisfy those standards. When considering information described in clause (i), (ii) or

(iii) above, the Determining Party may include costs of funding, to the extent costs of funding are not and would not be a component of the other information being utilised. Third parties supplying quotations pursuant to clause (i) above or market data pursuant to clause (ii) above may include, without limitation, dealers in the relevant markets, end-users of the relevant product, information vendors, brokers and other sources of market information.

Without duplication of amounts calculated based on information described in clause (i), (ii) or (iii) above, or other relevant information, and when it is commercially reasonable to do so, the Determining Party may in addition consider in calculating a Close-out Amount any loss or cost incurred in connection with its terminating, liquidating or re-establishing any hedge related to a Terminated Transaction or group of Terminated Transactions (or any gain resulting from any of them).

Commercially reasonable procedures used in determining a Close-out Amount may include the following:―

(1)application to relevant market data from third parties pursuant to clause (ii) above or information from internal sources pursuant to clause (iii) above of pricing or other valuation models that are, at the time of the determination of the Close-out Amount, used by the Determining Party in the regular course of its business in pricing or valuing transactions between the Determining Party and unrelated third parties that are similar to the Terminated Transaction or group of Terminated Transactions; and

(2)application of different valuation methods to Terminated Transactions or groups of Terminated Transactions depending on the type, complexity, size or number of the Terminated Transactions or group of Terminated Transactions.

“Confirmation” has the meaning specified in the preamble.

“consent” includes a consent, approval, action, authorisation, exemption, notice, filing, registration or exchange control consent.

“Contractual Currency” has the meaning specified in Section 8(a).

“Convention Court” means any court which is bound to apply to the Proceedings either Article 17 of the 1968 Brussels Convention on Jurisdiction and the Enforcement of Judgments in Civil and Commercial Matters or Article 17 of the 1988 Lugano Convention on Jurisdiction and the Enforcement of Judgments in Civil and Commercial Matters.

“Credit Event Upon Merger” has the meaning specified in Section 5(b).

“Credit Support Document” means any agreement or instrument that is specified as such in this Agreement.

“Credit Support Provider” has the meaning specified in the Schedule.

“Cross-Default” means the event specified in Section 5(a)(vi).

“Default Rate” means a rate per annum equal to the cost (without proof or evidence of any actual cost) to the relevant payee (as certified by it) if it were to fund or of funding the relevant amount plus 1% per annum.

“Defaulting Party” has the meaning specified in Section 6(a).

“Designated Event” has the meaning specified in Section 5(b)(v).

“Determining Party” means the party determining a Close-out Amount.

“Early Termination Amount” has the meaning specified in Section 6(e).

“Early Termination Date” means the date determined in accordance with Section 6(a) or 6(b)(iv).

“electronic messages” does not include e-mails but does include documents expressed in markup languages, and “electronic messaging system” will be construed accordingly.

“English law” means the law of England and Wales, and “English” will be construed accordingly.

“Event of Default” has the meaning specified in Section 5(a) and, if applicable, in the Schedule.

“Force Majeure Event” has the meaning specified in Section 5(b).

“General Business Day” means a day on which commercial banks are open for general business (including dealings in foreign exchange and foreign currency deposits).

“Illegality” has the meaning specified in Section 5(b).

“Indemnifiable Tax” means any Tax other than a Tax that would not be imposed in respect of a payment under this Agreement but for a present or former connection between the jurisdiction of the government or taxation authority imposing such Tax and the recipient of such payment or a person related to such recipient (including, without limitation, a connection arising from such recipient or related person being or having been a citizen or resident of such jurisdiction, or being or having been organised, present or engaged in a trade or business in such jurisdiction, or having or having had a permanent establishment or fixed place of business in such jurisdiction, but excluding a connection arising solely from such recipient or related person having executed, delivered, performed its obligations or received a payment under, or enforced, this Agreement or a Credit Support Document).

“law” includes any treaty, law, rule or regulation (as modified, in the case of tax matters, by the practice of any relevant governmental revenue authority), and “unlawful” will be construed accordingly.

“Local Business Day” means (a) in relation to any obligation under Section 2(a)(i), a General Business Day in the place or places specified in the relevant Confirmation and a day on which a relevant settlement system is open or operating as specified in the relevant Confirmation or, if a place or a settlement system is not so specified, as otherwise agreed by the parties in writing or determined pursuant to provisions contained, or incorporated by reference, in this Agreement, (b) for the purpose of determining when a Waiting Period expires, a General Business Day in the place where the event or circumstance that constitutes or gives rise to the Illegality or Force Majeure Event, as the case may be, occurs, (c) in relation to any other payment, a General Business Day in the place where the relevant account is located and, if different, in the principal financial centre, if any, of the currency of such payment and, if that currency does not have a single recognised principal financial centre, a day on which the settlement system necessary to accomplish such payment is open, (d) in relation to any notice or other communication, including notice contemplated under Section 5(a)(i), a General Business Day (or a day that would have been a General Business Day but for the occurrence of an event or circumstance which would, if it occurred with respect to payment, delivery or compliance related to a Transaction, constitute or give rise to an Illegality or a Force Majeure Event) in the place specified in the address for notice provided by the recipient and, in the case of a notice contemplated by Section 2(b), in the place where the relevant new account is to be located and (e) in relation to Section 5(a)(v)(2), a General Business Day in the relevant locations for performance with respect to such Specified Transaction.

“Local Delivery Day” means, for purposes of Sections 5(a)(i) and 5(d), a day on which settlement systems necessary to accomplish the relevant delivery are generally open for business so that the delivery is capable of being accomplished in accordance with customary market practice, in the place specified in the relevant Confirmation or, if not so specified, in a location as determined in accordance with customary market practice for the relevant delivery.

“Master Agreement” has the meaning specified in the preamble.

“Merger Without Assumption” means the event specified in Section 5(a)(viii).

“Multiple Transaction Payment Netting” has the meaning specified in Section 2(c).

“Non-affected Party” means, so long as there is only one Affected Party, the other party.

“Non-default Rate” means the rate certified by the Non-defaulting Party to be a rate offered to the Non-defaulting Party by a major bank in a relevant interbank market for overnight deposits in the applicable currency, such bank to be selected in good faith by the Non-defaulting Party for the purpose of obtaining a representative rate that will reasonably reflect conditions prevailing at the time in that relevant market.

“Non-defaulting Party” has the meaning specified in Section 6(a).

“Office” means a branch or office of a party, which may be such party’s head or home office.

“Other Amounts” has the meaning specified in Section 6(f).

“Payee” has the meaning specified in Section 6(f).

“Payer” has the meaning specified in Section 6(f).

“Potential Event of Default” means any event which, with the giving of notice or the lapse of time or both, would constitute an Event of Default.

“Proceedings” has the meaning specified in Section 13(b).

“Process Agent” has the meaning specified in the Schedule.

“rate of exchange” includes, without limitation, any premiums and costs of exchange payable in connection with the purchase of or conversion into the Contractual Currency.

“Relevant Jurisdiction” means, with respect to a party, the jurisdictions (a) in which the party is incorporated, organised, managed and controlled or considered to have its seat, (b) where an Office through which the party is acting for purposes of this Agreement is located, (c) in which the party executes this Agreement and (d) in relation to any payment, from or through which such payment is made.

“Schedule” has the meaning specified in the preamble.

“Scheduled Settlement Date” means a date on which a payment or delivery is to be made under Section 2(a)(i) with respect to a Transaction.

“Specified Entity” has the meaning specified in the Schedule.

“Specified Indebtedness” means, subject to the Schedule, any obligation (whether present or future, contingent or otherwise, as principal or surety or otherwise) in respect of borrowed money.

“Specified Transaction” means, subject to the Schedule, (a) any transaction (including an agreement with respect to any such transaction) now existing or hereafter entered into between one party to this Agreement (or any Credit Support Provider of such party or any applicable Specified Entity of such party) and the other party to this Agreement (or any Credit Support Provider of such other party or any applicable Specified Entity of such other party) which is not a Transaction under this Agreement but (i) which is a rate swap transaction, swap option, basis swap, forward rate transaction, commodity swap, commodity option, equity or equity index swap, equity or equity index option, bond option, interest rate option, foreign exchange transaction, cap transaction, floor transaction, collar transaction, currency swap transaction, cross-currency rate swap transaction, currency option, credit protection transaction, credit swap, credit default swap, credit default option, total return swap, credit spread transaction, repurchase transaction, reverse repurchase transaction, buy/sell-back transaction, securities lending transaction, weather index transaction or forward purchase or sale of a security, commodity or other financial instrument or interest (including any option with respect to any of these transactions) or (ii) which is a type of transaction that is similar to any transaction referred to in clause (i) above that is currently, or in the future becomes, recurrently entered into in the financial markets (including terms and conditions incorporated by reference in such agreement) and which is a forward, swap, future, option or other derivative on one or more rates, currencies, commodities, equity securities or other equity instruments, debt securities or other debt instruments, economic indices or measures of economic risk or value, or other benchmarks against which payments or deliveries are to be made, (b) any combination of these transactions and

(c) any other transaction identified as a Specified Transaction in this Agreement or the relevant confirmation.

“Stamp Tax” means any stamp, registration, documentation or similar tax.

“Stamp Tax Jurisdiction” has the meaning specified in Section 4(e).

“Tax” means any present or future tax, levy, impost, duty, charge, assessment or fee of any nature (including interest, penalties and additions thereto) that is imposed by any government or other taxing authority in respect of any payment under this Agreement other than a stamp, registration, documentation or similar tax.

“Tax Event” has the meaning specified in Section 5(b).

“Tax Event Upon Merger” has the meaning specified in Section 5(b).

“Terminated Transactions” means, with respect to any Early Termination Date, (a) if resulting from an Illegality or a Force Majeure Event, all Affected Transactions specified in the notice given pursuant to Section 6(b)(iv), (b) if resulting from any other Termination Event, all Affected Transactions and (c) if resulting from an Event of Default, all Transactions in effect either immediately before the effectiveness of the notice designating that Early Termination Date or, if Automatic Early Termination applies, immediately before that Early Termination Date.

“Termination Currency” means (a) if a Termination Currency is specified in the Schedule and that currency is freely available, that currency, and (b) otherwise, euro if this Agreement is expressed to be governed by English law or United States Dollars if this Agreement is expressed to be governed by the laws of the State of New York.

“Termination Currency Equivalent” means, in respect of any amount denominated in the Termination Currency, such Termination Currency amount and, in respect of any amount denominated in a currency other than the Termination Currency (the “Other Currency”), the amount in the Termination Currency determined by the party making the relevant determination as being required to purchase such amount of such Other Currency as at the relevant Early Termination Date, or, if the relevant Close-out Amount is determined as of a later date, that later date, with the Termination Currency at the rate equal to the spot exchange rate of the foreign exchange agent (selected as provided below) for the purchase of such Other Currency with the Termination Currency at or about 11:00 a.m. (in the city in which such foreign exchange agent is located) on such date as would be customary for the determination of such a rate for the purchase of such Other Currency for value on the relevant Early Termination Date or that later date. The foreign exchange agent will, if only one party is obliged to make a determination under Section 6(e), be selected in good faith by that party and otherwise will be agreed by the parties.

“Termination Event” means an Illegality, a Force Majeure Event, a Tax Event, a Tax Event Upon Merger or, if specified to be applicable, a Credit Event Upon Merger or an Additional Termination Event.

“Termination Rate” means a rate per annum equal to the arithmetic mean of the cost (without proof or evidence of any actual cost) to each party (as certified by such party) if it were to fund or of funding such amounts.

“Threshold Amount” means the amount, if any, specified as such in the Schedule.

“Transaction” has the meaning specified in the preamble.

“Unpaid Amounts” owing to any party means, with respect to an Early Termination Date, the aggregate of (a) in respect of all Terminated Transactions, the amounts that became payable (or that would have become payable but for Section 2(a)(iii) or due but for Section 5(d)) to such party under Section 2(a)(i) or 2(d)(i)(4) on or prior to such Early Termination Date and which remain unpaid as at such Early Termination Date, (b) in respect of each Terminated Transaction, for each obligation under Section 2(a)(i) which was (or would have been but for Section 2(a)(iii) or 5(d)) required to be settled by delivery to such party on or prior to such Early Termination Date and which has not been so settled as at such Early Termination Date, an amount equal to the fair market value of that which was (or would have been) required to be delivered and (c) if the Early Termination Date results from an Event of Default, a

Credit Event Upon Merger or an Additional Termination Event in respect of which all outstanding Transactions are

Affected Transactions, any Early Termination Amount due prior to such Early Termination Date and which remains unpaid as of such Early Termination Date, in each case together with any amount of interest accrued or other compensation in respect of that obligation or deferred obligation, as the case may be, pursuant to Section 9(h)(ii)(1) or (2), as appropriate. The fair market value of any obligation referred to in clause (b) above will be determined as of the originally scheduled date for delivery, in good faith and using commercially reasonable procedures, by the party obliged to make the determination under Section 6(e) or, if each party is so obliged, it will be the average of the Termination Currency Equivalents of the fair market values so determined by both parties.

“Waiting Period” means:―

(a)in respect of an event or circumstance under Section 5(b)(i), other than in the case of Section 5(b)(i)(2) where the relevant payment, delivery or compliance is actually required on the relevant day (in which case no Waiting Period will apply), a period of three Local Business Days (or days that would have been Local Business Days but for the occurrence of that event or circumstance) following the occurrence of that event or circumstance; and

(b)in respect of an event or circumstance under Section 5(b)(ii), other than in the case of Section 5(b)(ii)(2) where the relevant payment, delivery or compliance is actually required on the relevant day (in which case no

Waiting Period will apply), a period of eight Local Business Days (or days that would have been Local Business Days but for the occurrence of that event or circumstance) following the occurrence of that event or circumstance.

IN WITNESS WHEREOF the parties have executed this document on the respective dates specified below with effect from the date specified on the first page of this document.	 

______________________________
(Name of Party)
By: ______________________________
Name: ______________________________
Title: ______________________________
Date: ______________________________

______________________________
(Name of Party)

By: ______________________________
Name: ______________________________
Title: ______________________________
Date: ______________________________

`.replace(/\r?\n/g, '<br />');
export default {
  name: 'baal',
  components: {
    [Button.name]: Button,
  },
  data() {
    return {
      content,
    };
  },
  methods: {
    async before() {
      const data = JSON.parse(JSON.stringify(this.submitForm));
      const res = await this.$api.postAgreementState({
        ...data,
        isda_2002: '是',
      });
      if (res) {
        this.$router.back();
      }
    },
  },
  setup() {
    const isDisabled = ref(true);
    const submitForm = ref({});
    onMounted(async () => {
      const store = useStore();
      store.commit('setTitleValue', '协议详情');
      store.commit('setStepsShow', false);

      const result = await httpApi.getAgreementState();
      const { data } = result;
      isDisabled.value = data.isda_2002 === '是';
      submitForm.value = data;
      httpApi.formLog(submitForm.value, isDisabled);
    });
    return {
      isDisabled,
      submitForm,
    };
  },
};

</script>

<style lang="less">
  .appendix {
    margin-top: @space-2;
    text-align: left;

    .content {
      padding: @space-2;
      background: @contentColor;
      font-size: @font-size-2;
    }
    .btn {
      width: 80%;
      margin: @space-0 auto;
      margin-bottom: 0;
    }
  }
</style>
